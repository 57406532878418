import React from "react";
import { Link } from "gatsby";

import Headline from "../../../components/headline";

import Head from "../../../components/head";

import caretRight from "../../../images/caret-right.png";

import OurResearchStyles from "./ourResearch.module.css";

import TextWrapAroundImg from "../../../components/textWrapAroundImg";

import { graphql } from "gatsby";
export const query = graphql`
  query {
    allContentfulOurResearchPage {
      edges {
        node {
          section1Headline {
            section1Headline
          }
          section1Paragraph {
            section1Paragraph
          }
          section1Image {
            file {
              url
            }
          }
          section2Headline {
            section2Headline
          }
          section2Image {
            file {
              url
            }
          }
          section2Paragraph {
            section2Paragraph
          }
          section3Headline {
            section3Headline
          }
          section3Paragraph1 {
            section3Paragraph1
          }
          section3Paragraph2 {
            section3Paragraph2
          }
          section4Header {
            section4Header
          }
          section4Image {
            file {
              url
            }
          }
          section4Paragraph {
            section4Paragraph
          }
        }
      }
    }
  }
`;

const OurResearchPage = props => {
  return (
    <>
      <Head title="Our Research"></Head>
      <Headline
        title={
          props.data.allContentfulOurResearchPage.edges[0].node.section1Headline
            .section1Headline
        }
        lightBackground={true}
      ></Headline>

      <TextWrapAroundImg
        imgRight={
          props.data.allContentfulOurResearchPage.edges[0].node.section1Image
            .file.url
        }
        lightBackground={true}
      >
        <p style={{ whiteSpace: "pre-wrap" }}>
          {
            props.data.allContentfulOurResearchPage.edges[0].node
              .section1Paragraph.section1Paragraph
          }
        </p>
      </TextWrapAroundImg>

      <section
        style={{ backgroundColor: "#fff" }}
        className={OurResearchStyles.ourResearchSection}
      >
        <div>
          <h2>
            {
              props.data.allContentfulOurResearchPage.edges[0].node
                .section2Headline.section2Headline
            }
          </h2>
          <div>
            <img
              src={
                props.data.allContentfulOurResearchPage.edges[0].node
                  .section2Image.file.url
              }
            />

            <p style={{ whiteSpace: "pre-wrap" }}>
              {
                props.data.allContentfulOurResearchPage.edges[0].node
                  .section2Paragraph.section2Paragraph
              }
            </p>
          </div>
        </div>
      </section>

      <section
        className={OurResearchStyles.researchBackground}
        style={{ backgroundColor: "#fff" }}
      >
        <div>
          <h2>
            {
              props.data.allContentfulOurResearchPage.edges[0].node
                .section3Headline.section3Headline
            }
          </h2>
          <div>
            <div>
              <p>
                {
                  props.data.allContentfulOurResearchPage.edges[0].node
                    .section3Paragraph1.section3Paragraph1
                }
              </p>
            </div>
            <div id={OurResearchStyles.researchRight}>
              <div>
                <p>
                  {
                    props.data.allContentfulOurResearchPage.edges[0].node
                      .section3Paragraph2.section3Paragraph2
                  }
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Link
                    to="/programs/research/janus-hypothesis"
                    style={{
                      textDecoration: "none",
                      color: "#ba0c2f",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    Read more about the Janus Hypothesis{" "}
                    <img
                      src={caretRight}
                      style={{
                        height: "9px",
                        marginLeft: "4px",
                        width: "auto",
                      }}
                    ></img>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TextWrapAroundImg
        imgRight={
          props.data.allContentfulOurResearchPage.edges[0].node.section4Image
            .file.url
        }
        paragraphHeader={
          props.data.allContentfulOurResearchPage.edges[0].node.section4Header
            .section4Header
        }
        lightBackground={true}
      >
        <p style={{ whiteSpace: "pre-wrap" }}>
          {
            props.data.allContentfulOurResearchPage.edges[0].node
              .section4Paragraph.section4Paragraph
          }
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Link
            to="/donate"
            style={{
              textDecoration: "none",
              color: "#ba0c2f",
              fontWeight: "600",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            Support TSS Research{" "}
          </Link>
        </div>
      </TextWrapAroundImg>
    </>
  );
};

export default OurResearchPage;
